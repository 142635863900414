//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    createEntity(type){
      this.$router.push({name: 'CreateEntity' , params:{type:type} , force: true})
    },
  },
  computed: {
    first_name() {
      return this.$store.state.user.first_name;
    }
  },
  data() {
    return {
      showGroupCreate: false,
      showEventCreate: false
    }
  },
  
  }
